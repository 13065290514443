import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import App from '~/App.vue';
import Vue from 'vue';

const app = new Vue(App).$mount('#app');

export default app;
