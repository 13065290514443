<template lang="pug">
	main
		div.spacer
		h1 {{ siteTitle }}
		section#upload-speed
			h2 Upload Speed
			form.flex-row(@submit="formHandler")
				label.rate
					h3 Rate
					input(v-model.number="uploadSpeed", type="number", min="0")
				label.unit
					h3 Unit
					select(v-model="uploadUnit")
						option(v-for="unit in speedUnits", :key="unit", :value="unit") {{ unit }}
		section#recommended-settings
			h2 Recommended Settings
			div.block-container
				div.block(v-for="(value, property) in settings")
					strong.header {{ property | camelPretty }}
					span.data {{ value }}
		div.spacer
		SiteFooter(:sections-to-include="footerSectionsToInclude")
</template>

<script>
	import torrentCalc from 'torrentcalc';

	const SiteFooter = async () => await import('@gabeotisbenson/gabes-foot');

	const speedUnits = [
		'Kbps',
		'KBps',
		'Mbps',
		'MBps',
		'Gbps',
		'GBps'
	];

	export default {
		name: 'BitTorrentSettingsCalculator',
		components: { SiteFooter },
		filters: {
			camelPretty: str => {
				// If no string, return empty string
				if (!str) return '';

				// Make sure we're working with a string
				let formattedStr = str.toString();

				// Split based on capital letters and add spaces
				formattedStr = formattedStr.replace(/(?<firstLetter>[A-Z]+)*(?<word>[A-Z][a-z])/gu, '$1 $2');

				// Capitalize the first letter
				formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);

				// Return it
				return formattedStr;
			}
		},
		data () {
			return {
				footerSectionsToInclude: ['made-by-message', 'philosophy', 'donate-links'],
				siteTitle: 'BitTorrent Settings Calculator',
				uploadSpeed: 0,
				uploadUnit: speedUnits[0],
				speedUnits
			};
		},
		computed: {
			settings () {
				const settings = torrentCalc(this.uploadSpeed);

				return settings[this.uploadUnit];
			}
		},
		methods: {
			formHandler (e) {
				const defaultKey = 'preventDefault';
				if (defaultKey in e && typeof e[defaultKey] === 'function') e[defaultKey]();

				return false;
			}
		}
	};
</script>

<style lang="scss" scoped>
	main {
		padding: 1rem;
		min-height: 100vh;
		max-width: 60rem;
		display: flex;
		flex-direction: column;

		div.spacer { flex-grow: 1; }
	}

	section#upload-speed {
		background: #f4f5f6;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		margin: 0.25rem;

		h2 {
			background-color: #9b4dca;
			color: #fff;
			padding: 1rem;
			text-align: center;
			margin: 0;
		}

		form {
			justify-content: center;
			padding: 0 1rem;

			label {
				padding-bottom: 1rem;
				flex-grow: 1;
				flex-shrink: 1;

				&.rate { flex-basis: 80%; }

				h3 {
					font-weight: 400;
					font-size: 1.4rem;
					margin: 0.5rem 0;
				}

				input,
				select {
					font-size: 3rem;
					width: 100%;
					background: #fbfcfd;
					border: 2px dashed #ddd;
					outline: none;

					&:active,
					&:focus {
						border-color: #000;
					}
				}
			}
		}
	}

	div.block-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		div.block {
			display: flex;
			flex-direction: column;
			margin: 0.5rem;
			flex-grow: 1;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

			strong.header {
				background-color: #9b4dca;
				color: #fff;
				padding: 1rem;
				text-align: center;
			}

			span.data {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				font-size: 3rem;
				background-color: #f4f5f6;
				line-height: 3em;
			}
		}
	}

	@media (min-width: 40rem) {
		form.flex-row {
			display: flex;

			label {
				margin-right: 1rem;

				&:last-of-type { margin-right: 0; }
			}

			.column-75 {
				width: 74.5%;
			}

			.column-25 {
				width: 24.5%;
			}
		}
	}
</style>
